const BaseballField = (props) => {
  return(<svg id="mlb-gamecast__view--field" className="mlb-gamecast__view--field mlb-gamecast__view--venue-89" viewBox="0 0 390 188">
    <g transform="matrix(1,0,0,1,-129,-118)" className="base base__0">
    <path d="M375,249.5l-8-3.5l-8,3.5l0,0v1.8l8,3.5v0l8-3.5L375,249.5L375,249.5z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__0"></path>
    <path d="M 367,253 367,254.8 375,251.3 375,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__20"></path>
    <path d="M 359,251.3 367,254.8 367,253 359,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__30"></path>
    </g><g transform="matrix(1,0,0,1,-172,-136)" className="base base__1">
    <path d="M375,249.5l-8-3.5l-8,3.5l0,0v1.8l8,3.5v0l8-3.5L375,249.5L375,249.5z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__0"></path>
    <path d="M 367,253 367,254.8 375,251.3 375,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__20"></path>
    <path d="M 359,251.3 367,254.8 367,253 359,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__30"></path></g>
    <g transform="matrix(1,0,0,1,-215,-118)" className="base base__2">
    <path d="M375,249.5l-8-3.5l-8,3.5l0,0v1.8l8,3.5v0l8-3.5L375,249.5L375,249.5z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__0"></path><path d="M 367,253 367,254.8 375,251.3 375,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__20"></path>
    <path d="M 359,251.3 367,254.8 367,253 359,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__30"></path></g><g transform="matrix(1,0,0,1,-172,-98)" className="base base__3"><path d="M375,249.5l-8-3.5l-8,3.5l0,0v1.8l8,3.5v0l8-3.5L375,249.5L375,249.5z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__0"></path>
    <path d="M 367,253 367,254.8 375,251.3 375,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__20"></path><path d="M 359,251.3 367,254.8 367,253 359,249.5  z" style={{strokeWidth: 0, strokeOpacity: 1}} className="base__fill__30"></path></g><g className="base-runner base__first"><text x="238" y="123" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}}></text></g>
    <g className="base-runner base__second"><text x="195" y="105" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}}></text></g><g className="base-runner base__third"><text x="151" y="123" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}}></text></g><g className="base-runner base__home"><text x="195" y="167" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle", opacity: 1}}></text></g>
    <g className="mlb-gamecast__defense mlb-gamecast__defense--active"><text x="195" y="168" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="catcher">Y. Gomes</text><text x="140" y="125" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="third-base">A. Cabrera</text><text x="245" y="125" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="first-base">E. Thames</text>
    <text x="155" y="100" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="short-stop">T. Turner</text><text x="235" y="100" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="second-base">L. Garcia</text>
    <text x="95" y="75" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="left-field">J. Soto</text><text x="195" y="55" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="center-field">V. Robles</text><text x="290" y="75" fill="#ffffff" style={{fontSize: '11px', textAnchor: "middle"}} className="right-field">A. Eaton</text></g>
    <g className="baseball" style={{display:"none"}}><circle cx="8" cy="8" r="3" fill="#ffffff" stroke="#000000" style={{strokeWidth: "0.8"}}></circle></g><g transform="matrix(0.6475,0,0,0.6475,246.7964,58.7987)" className="hit-result hit-result-out" style={{opacity: 0}}><svg width="11px" height="5px" viewBox="0 0 11 5" version="1.1">
    <g id="--Field-Toolkit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Artboard" transform="translate(-323.000000, -417.000000)"><g id="Group-9" transform="translate(323.000000, 417.000000)"><g id="indicator" transform="translate(9.000000, 1.000000)" fill="#2C6F11"><path d="M0.89506087,0.4915 C0.89506087,0.4615 0.894017391,0.4415 0.894017391,0.4415 L0.8888,0.4415 C0.891930435,0.4585 0.892973913,0.4745 0.89506087,0.4915" id="Fill-10"></path></g>
    <g id="Group-8" strokeWidth="0.5" stroke="#1D1E1F" fill="#1D1E1F"><polygon id="Rectangle-2" points="9.3922999 0.754628932 1.17403749 4.28087912 0 3.77712909 8.21826241 0.250878906"></polygon><polygon id="Rectangle-2" points="10.1922999 3.77712909 9.01826241 4.28087912 0.8 0.754628932 1.97403749 0.250878906">
    </polygon></g></g></g></g></svg></g>
      <g transform="matrix(1,0,0,1,158.04,51.0733)" className="hit-result hit-result-hit" style={{opacity: 0}}><svg width="24px" height="12px" viewBox="0 0 24 12" version="1.1">
        <defs>
          <polygon id="path-1" points="0 12 0 0 23.4292174 0 23.4292174 12"></polygon>
        </defs>
        <g id="Field-Toolkit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="field-toolkit" transform="translate(-40.000000, -2026.000000)">
        <g id="indicator" transform="translate(40.000000, 2026.000000)">
          <path d="M20.0190261,6.0123 C20.0190261,8.1643 16.3105043,9.9083 11.7369391,9.9083 C7.16233043,9.9083 3.4538087,8.1643 3.4538087,6.0123 C3.4538087,3.8603 7.16233043,2.1163 11.7369391,2.1163 C16.3105043,2.1163 20.0190261,3.8603 20.0190261,6.0123" id="Fill-1" fillOpacity="0.2" fill="#000000"></path>
          <g id="Group-5"><mask id="mask-2" fill="white"></mask><g id="Clip-4"></g>
          <path d="M11.714713,10.5 C6.86253913,10.5 2.92862609,8.485 2.92862609,6 C2.92862609,3.515 6.86253913,1.5 11.714713,1.5 C16.566887,1.5 20.5008,3.515 20.5008,6 C20.5008,8.485 16.566887,10.5 11.714713,10.5 M11.714713,0 C5.24514783,0 -0.000417391305,2.686 -0.000417391305,6 C-0.000417391305,9.314 5.24514783,12 11.714713,12 C18.1842783,12 23.4298435,9.314 23.4298435,6 C23.4298435,2.686 18.1842783,0 11.714713,0" id="Fill-3" fill="#6DBF38" mask="url(#mask-2)"></path>
        </g>
        <path d="M20.5006957,6 C20.5006957,8.485 16.5667826,10.5 11.7146087,10.5 C6.86243478,10.5 2.92852174,8.485 2.92852174,6 C2.92852174,3.515 6.86243478,1.5 11.7146087,1.5 C16.5667826,1.5 20.5006957,3.515 20.5006957,6 Z" id="Stroke-6" stroke="#71D133"></path>
        <path d="M11.6889391,7.7728 C8.88615652,7.7728 6.60615652,6.8458 6.48302609,5.6828 C6.47885217,5.9488 6.47154783,6.3928 6.47154783,6.4548 C6.47154783,7.6638 8.80789565,8.6428 11.6889391,8.6428 C14.5699826,8.6428 16.9063304,7.6638 16.9063304,6.4548 C16.9063304,6.3608 16.901113,5.9288 16.8969391,5.6608 C16.8019826,6.8338 14.5094609,7.7728 11.6889391,7.7728" id="Fill-8" fill="#043E52"></path>
        <path d="M16.8950609,5.4915 C16.8950609,5.4615 16.8940174,5.4415 16.8940174,5.4415 L16.8888,5.4415 C16.8919304,5.4585 16.8929739,5.4745 16.8950609,5.4915" id="Fill-10" fill="#2C6F11"></path>
        <path d="M6.47154783,5.5448 C6.47154783,6.7528 8.80789565,7.7328 11.6889391,7.7328 C14.5699826,7.7328 16.9063304,6.7528 16.9063304,5.5448 C16.9063304,4.3368 14.5699826,3.3568 11.6889391,3.3568 C8.80789565,3.3568 6.47154783,4.3368 6.47154783,5.5448" id="Fill-12" fill="#26B8EC"></path>
        </g></g></g></svg>
      </g>
    </svg>);
};

export default BaseballField;