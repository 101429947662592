const BasketballCourt = (props) => {
  return (
    <svg id="shotChart-02-svg" style={{paddingTop: '32px', height: '100%', maxWidth:'500px', width:'90%', overflow: 'visible'}} preserveAspectRatio="xMinYMax meet" viewBox="0 0 590 120">
      <defs>
        <radialGradient cx="0" cy="0" r="5" id="radialGradientSij1i6j9n2c">
          <stop offset="0%" stopColor="#d56900"></stop>
          <stop offset="100%" stopColor="#000000"></stop>
        </radialGradient>
        <radialGradient cx="0" cy="0" r="5" id="radialGradientSij1i6j9n2t"><stop offset="0%" stopColor="#d56900"></stop>
          <stop offset="100%" stopColor="#000000"></stop></radialGradient><radialGradient cx="0" cy="0" r="5" id="radialGradientSij1i6j9n3a">
          <stop offset="0%" stopColor="#d56900"></stop><stop offset="100%" stopColor="#000000"></stop>
        </radialGradient>
      </defs>
      <g className="court">
      <path d="M 550.7,0 584,109.5 0,109.5 33.3,0 z" fill="#ddc8ad" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 584,109.5 583.8,114 0.2,114 0,109.5 z" fill="#968775" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path><path d="M566.8,49.5"></path>
      <path d="M 131.6,34.6 125.2,69.6 12.1,69.6 22.7,34.6 z" fill="#d9ba93" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 561.3,34.6 571.9,69.6 458.8,69.6 452.4,34.6 z" fill="#d9ba93" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 386.3,0 386.7,3.9 384.9,3.9 384.5,0 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 199.5,0 199.1,3.9 197.3,3.9 197.7,0 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 460.4,32.5 460.8,34.4 460.8,34.6 459,34.6 459,34.4 458.6,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 125.4,32.5 125,34.4 125,34.6 123.2,34.6 123.2,34.4 123.6,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 71.7,32.5 71.2,34.4 71.1,34.6 69.3,34.6 69.4,34.4 69.9,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 79.3,32.5 78.8,34.4 78.8,34.6 76.9,34.6 77,34.4 77.5,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 483,32.5 483.4,34.4 483.4,34.6 481.6,34.6 481.6,34.4 481.2,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 514.1,32.5 514.6,34.4 514.7,34.6 512.9,34.6 512.8,34.4 512.3,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 506.5,32.5 507,34.4 507.1,34.6 505.2,34.6 505.2,34.4 504.7,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 102.8,32.5 102.4,34.4 102.4,34.6 100.6,34.6 100.6,34.4 101,32.5 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 125,34.4 131.6,34.4 130.8,38.6 129,38.6 129.6,35.1 22.5,35.1 22.7,34.4 69.4,34.4 69.3,34.6 71.1,34.6 71.2,34.4 77,34.4 76.9,34.6 78.8,34.6 78.8,34.4 100.6,34.4 100.6,34.6 102.4,34.6 102.4,34.4 123.2,34.4 123.2,34.6 125,34.6 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 514.6,34.4 561.3,34.4 561.5,35.1 454.4,35.1 455,38.6 453.2,38.6 452.4,34.4 459,34.4 459,34.6 460.8,34.6 460.8,34.4 481.6,34.4 481.6,34.6 483.4,34.6 483.4,34.4 505.2,34.4 505.2,34.6 507.1,34.6 507,34.4 512.8,34.4 512.9,34.6 514.7,34.6 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M129,38.6h1.8l0,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0S129.1,38.6,129,38.6 L129,38.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M453.2,38.6h1.8h0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0 C453.5,38.6,453.3,38.6,453.2,38.6L453.2,38.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M21.2,39.3l0.2-0.7h107.5c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0 c-0.4,0-0.9,0-1.3,0.1l0.1,0.5h-5.6c0,0-0.1,0-0.1,0s0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0H21.2z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M455.1,38.6l107.5,0l0.2,0.7H466c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l-5.7,0 l0.1-0.5c-0.4,0-0.9,0-1.3-0.1s-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0S455.5,38.6,455.1,38.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M460.1,39.3l0,0.2c-0.4,0-0.8,0-1.2-0.1c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0l-0.1-0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0 c0,0,0.1,0,0.1,0c0.4,0,0.9,0,1.3,0s0.9,0,1.3,0c0.4,0,0.9,0,1.3,0s0.9,0,1.3,0.1L460.1,39.3z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M128.9,38.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l-0.1,0.7c0,0-0.1,0-0.1,0 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0 c-0.4,0-0.8,0-1.2,0.1l0-0.2l-0.1-0.5c0.4,0,0.8,0,1.3-0.1c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0 C128.1,38.7,128.5,38.6,128.9,38.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M471.1,40.1l-0.7,0.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.1-1.3-0.2c-0.4-0.1-0.9-0.1-1.3-0.2 c-0.4-0.1-0.9-0.1-1.3-0.2l0.4-0.6l0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.4,0.1,0.9,0.1,1.3,0.2 c0.4,0.1,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.1,1.3,0.2C470.3,39.9,470.7,40,471.1,40.1z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M118.2,39.3L118.2,39.3l0.4,0.6c-0.4,0.1-0.9,0.1-1.3,0.2c-0.4,0.1-0.9,0.1-1.3,0.2 c-0.4,0.1-0.8,0.1-1.3,0.2c-0.4,0.1-0.8,0.1-1.2,0.2l-0.7-0.6c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.1,1.3-0.2 c0.4-0.1,0.9-0.1,1.3-0.2c0.4-0.1,0.9-0.1,1.3-0.2c0,0,0,0,0.1,0c0,0,0,0,0.1,0S118.1,39.3,118.2,39.3 C118.2,39.3,118.2,39.3,118.2,39.3z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M480.7,42.5l-1.2,0.5c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.7-0.2-1.1-0.3 c-0.4-0.1-0.8-0.2-1.1-0.3l1-0.5c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.8,0.2,1.1,0.3 C480,42.2,480.4,42.4,480.7,42.5z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M103.3,42.5c0.4-0.1,0.7-0.2,1.1-0.4c0.4-0.1,0.7-0.2,1.1-0.3c0.4-0.1,0.8-0.2,1.2-0.3 c0.4-0.1,0.8-0.2,1.2-0.3l0.9,0.5c-0.4,0.1-0.8,0.2-1.1,0.3c-0.4,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.7,0.2-1.1,0.3 c-0.4,0.1-0.7,0.2-1,0.3L103.3,42.5z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M100.7,44.5c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.5,0.3-0.8,0.4c-0.3,0.1-0.5,0.3-0.8,0.4 c-0.2,0.1-0.5,0.3-0.7,0.4l-1.6-0.3c0.2-0.2,0.5-0.3,0.8-0.5c0.3-0.2,0.5-0.3,0.8-0.5c0.3-0.1,0.6-0.3,0.9-0.4 c0.3-0.1,0.6-0.3,0.9-0.4L100.7,44.5z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M488.1,45.9l-1.6,0.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.5-0.3-0.8-0.4 c-0.3-0.1-0.6-0.3-0.9-0.4l1.4-0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.5,0.3,0.8,0.5 C487.6,45.5,487.8,45.7,488.1,45.9z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M95.1,48c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.2-0.3,0.3-0.4,0.5 c-0.1,0.2-0.2,0.3-0.3,0.5l-1.8-0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.3-0.3,0.4-0.5 c0.2-0.2,0.3-0.3,0.5-0.5L95.1,48z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M492.3,49.9l-1.8,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.2-0.3-0.3-0.4-0.5 c-0.1-0.2-0.3-0.3-0.5-0.5l1.7-0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.5 C492.1,49.5,492.2,49.7,492.3,49.9z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M92.8,52c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0.1,0.5c0,0.2,0.1,0.3,0.1,0.5l-1.8,0.1 c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.5L92.8,52z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M491.2,52l1.9,0c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.4-0.1,0.5L491,54 c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5C491.2,52.3,491.2,52.2,491.2,52z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M487.9,57.8c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.3,0.5-0.5 c0.1-0.2,0.3-0.3,0.4-0.5l1.8,0.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.5,0.5 c-0.2,0.2-0.4,0.3-0.6,0.5L487.9,57.8z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M92.3,56.2l1.8-0.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.3,0.3,0.5,0.5 c0.2,0.2,0.4,0.3,0.6,0.4l-1.7,0.4c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.3-0.5-0.5c-0.2-0.2-0.3-0.3-0.5-0.5 C92.6,56.5,92.5,56.4,92.3,56.2z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M532.1,42.6l0.2,0.7h-4.6c-0.2,0-1.4,0-3.2,0.2c-1.7,0.2-4,0.5-6.1,1c-2.2,0.6-4.2,1.3-5.7,2.5 c-1.4,1.1-2.2,2.6-1.7,4.6c0.5,2,2,3.5,4,4.6c2,1.2,4.5,2,7,2.6c2.5,0.6,4.9,0.9,6.8,1.1c1.9,0.2,3.2,0.2,3.3,0.2h4.8l0.2,0.7 h-4.8c-0.1,0-1.4,0-3.4-0.2c-2-0.2-4.7-0.5-7.5-1.2c-2.7-0.6-5.5-1.5-7.8-2.8c-2.2-1.3-3.9-2.9-4.4-5c-0.5-2.1,0.4-3.7,2-5 c1.6-1.2,3.9-2.1,6.3-2.7c2.4-0.6,4.9-0.9,6.8-1.1c1.9-0.2,3.2-0.2,3.3-0.2H532.1z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M56.6,42.6c0.1,0,1.4,0,3.3,0.2c1.9,0.2,4.4,0.5,6.8,1.1c2.4,0.6,4.7,1.5,6.3,2.7c1.6,1.2,2.5,2.8,2,5 c-0.5,2.1-2.2,3.8-4.4,5c-2.2,1.3-5,2.1-7.8,2.8c-2.7,0.6-5.4,1-7.4,1.2c-2,0.2-3.4,0.2-3.4,0.2H47l0.2-0.7H52 c0.2,0,1.5,0,3.3-0.2c1.9-0.2,4.3-0.5,6.8-1.1c2.5-0.6,5-1.4,7-2.6c2-1.2,3.5-2.7,4-4.6c0.5-1.9-0.3-3.4-1.7-4.6 c-1.4-1.1-3.5-1.9-5.7-2.5c-2.2-0.6-4.4-0.9-6.1-1c-1.7-0.2-3-0.2-3.2-0.2h-4.6l0.2-0.7H56.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M98.8,59.5c0.3,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,0.9,0.4 c0.3,0.1,0.6,0.2,0.9,0.3l-1.3,0.5c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.3-0.9-0.4 c-0.3-0.1-0.6-0.3-0.9-0.4L98.8,59.5z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M481.7,60.9c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.1,0.6-0.2,0.9-0.4 c0.3-0.1,0.5-0.3,0.8-0.4l1.5,0.5c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.3-0.9,0.4 c-0.3,0.1-0.7,0.2-1,0.4L481.7,60.9z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M472.9,63.1c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.2,1.2-0.2c0.4-0.1,0.8-0.2,1.2-0.2 c0.4-0.1,0.8-0.2,1.1-0.3l1.1,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.2-1.2,0.2 c-0.4,0.1-0.8,0.2-1.3,0.2L472.9,63.1z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M105.4,62.8l1.1-0.6c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.2,1.2,0.2c0.4,0.1,0.8,0.2,1.2,0.2 c0.4,0.1,0.8,0.1,1.2,0.2l-0.9,0.7c-0.4-0.1-0.8-0.1-1.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.2-1.2-0.3 C106.1,62.9,105.7,62.9,105.4,62.8z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M115.8,64.2l0.4-0.4c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0.1,1.3,0.1 c0.4,0,0.9,0,1.4,0.1l0,0.1l-0.3,0.6c-0.5,0-1,0-1.4-0.1c-0.5,0-0.9-0.1-1.4-0.1c-0.5,0-0.9-0.1-1.4-0.1c-0.5,0-0.9-0.1-1.4-0.1 L115.8,64.2z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M467.8,63.8l0.3,0.4l0.2,0.3c-0.5,0-0.9,0.1-1.4,0.1c-0.5,0-0.9,0.1-1.4,0.1c-0.5,0-0.9,0.1-1.4,0.1 c-0.5,0-0.9,0-1.4,0.1l-0.3-0.6l0-0.1c0.5,0,0.9,0,1.4-0.1c0.4,0,0.9,0,1.3-0.1c0.4,0,0.9-0.1,1.3-0.1 C466.9,63.8,467.4,63.8,467.8,63.8z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M457.9,64.9L457.9,64.9c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0c0.2,0,0.3,0,0.5,0h-0.1H457.9z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M455.1,38.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0.1,0.7c0,0,0.1,0,0.1,0 s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l4.6,24.9l0.1,0.7c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0 c-0.2,0-0.3,0-0.5,0c-4.7-0.1-9.2-0.5-13.4-1.3c-4.1-0.7-7.9-1.7-11.1-2.9c-3.2-1.2-5.8-2.6-7.7-4.2c-1.9-1.5-3.1-3.2-3.3-5 c-0.3-1.7,0.4-3.4,1.9-4.9c1.4-1.5,3.6-2.9,6.4-4c2.8-1.2,6.1-2.1,10-2.8c3.8-0.7,8-1.1,12.6-1.2c0.2,0,0.3,0,0.5,0 c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0C454.8,38.6,454.9,38.6,455.1,38.6z M457.8,64.2l-4.4-24.8c-4.3,0.1-8.3,0.5-11.9,1.1 c-3.6,0.6-6.8,1.5-9.4,2.6c-2.6,1.1-4.7,2.4-6,3.8c-1.4,1.4-2,3-1.7,4.7c0.2,1.7,1.3,3.2,3.1,4.7c1.8,1.5,4.3,2.8,7.3,3.9 c3,1.1,6.6,2.1,10.5,2.8C449.1,63.6,453.4,64.1,457.8,64.2" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M468.4,64.5l-0.2-0.3h102.2l0.2,0.7H459.9l-0.1-0.7h2.7l0.3,0.6c0.5,0,1,0,1.4-0.1c0.5,0,0.9-0.1,1.4-0.1 c0.5,0,0.9-0.1,1.4-0.1C467.5,64.5,467.9,64.5,468.4,64.5z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M121.5,64.2h2.7l-0.1,0.7H13.5l0.2-0.7h102.2l-0.2,0.3c0.5,0,0.9,0.1,1.4,0.1c0.5,0,0.9,0.1,1.4,0.1 c0.5,0,0.9,0.1,1.4,0.1c0.5,0,1,0,1.4,0.1L121.5,64.2z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M130.8,38.7c4.5,0.1,8.8,0.5,12.6,1.2c3.8,0.7,7.2,1.6,10,2.8c2.8,1.2,5,2.5,6.4,4 c1.4,1.5,2.1,3.2,1.9,4.9c-0.3,1.8-1.4,3.4-3.3,5c-1.9,1.5-4.5,3-7.7,4.2c-3.2,1.2-7,2.2-11.1,2.9c-4.1,0.7-8.7,1.2-13.4,1.3 c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0l0.1-0.7l4.6-24.9c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0 c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.1-0.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0.2,0,0.3,0,0.5,0 c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0C130.5,38.6,130.6,38.6,130.8,38.7z M126.1,64.2c4.5-0.1,8.7-0.5,12.7-1.2 c3.9-0.7,7.5-1.6,10.5-2.8c3-1.1,5.5-2.5,7.3-3.9c1.8-1.5,2.9-3,3.1-4.7c0.2-1.7-0.4-3.2-1.7-4.7c-1.3-1.4-3.4-2.7-6-3.8 c-2.6-1.1-5.8-2-9.4-2.6c-3.6-0.6-7.6-1-11.9-1.1L126.1,64.2" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M124.2,64.9L124.2,64.9c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0c0.2,0,0.3,0,0.5,0l0,0H124.2z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 459.8,64.9 460.5,68.7 571.7,68.7 571.9,69.4 523.4,69.4 523.4,69.3 521.5,69.3 521.5,69.4 515.5,69.4 515.5,69.3 513.6,69.3 513.6,69.4 490.9,69.4 490.9,69.3 489,69.3 489.1,69.4 467.4,69.4 467.4,69.3 465.5,69.3 465.6,69.4 458.8,69.4 457.9,64.9 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 123.5,68.7 124.2,64.9 126.1,64.9 125.2,69.4 118.4,69.4 118.5,69.3 116.6,69.3 116.6,69.4 94.9,69.4 95,69.3 93.1,69.3 93.1,69.4 70.4,69.4 70.4,69.3 68.5,69.3 68.5,69.4 62.5,69.4 62.5,69.3 60.6,69.3 60.6,69.4 12.1,69.4 12.3,68.7 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 523.4,69.3 523.4,69.4 524,71.6 522.1,71.6 521.5,69.4 521.5,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 490.9,69.3 490.9,69.4 491.4,71.6 489.5,71.6 489.1,69.4 489,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 467.4,69.3 467.4,69.4 467.9,71.6 466,71.6 465.6,69.4 465.5,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 62.5,69.3 62.5,69.4 61.9,71.6 60,71.6 60.6,69.4 60.6,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 95,69.3 94.9,69.4 94.5,71.6 92.6,71.6 93.1,69.4 93.1,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 70.4,69.3 70.4,69.4 69.9,71.6 68,71.6 68.5,69.4 68.5,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 118.5,69.3 118.4,69.4 118,71.6 116.1,71.6 116.6,69.4 116.6,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 515.5,69.3 515.5,69.4 516,71.6 514.1,71.6 513.6,69.4 513.6,69.3 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M552.6,5.9l0.2,0.6h-83.1c-0.6,0.1-2,0.3-4.1,0.8c-2.1,0.4-4.7,1-7.8,1.7c-3.1,0.8-6.6,1.7-10.3,2.8 c-3.7,1.1-7.6,2.5-11.4,4.1c-2.4,1-4.7,2-6.9,3.1c-2.2,1.1-4.2,2.2-6.1,3.3c-1.9,1.1-3.7,2.3-5.3,3.5c-1.6,1.2-3.1,2.5-4.4,3.8 c-1.7,1.6-3.1,3.3-4.3,5c-1.2,1.7-2.2,3.5-2.9,5.3c-0.7,1.8-1.2,3.7-1.4,5.7c-0.2,1.9-0.2,3.9,0,6c0.3,2,0.8,4.1,1.5,6.1 c0.7,2,1.7,4,3,5.9c1.2,1.9,2.7,3.8,4.4,5.7c1.7,1.9,3.7,3.7,5.9,5.5c1.8,1.4,3.7,2.8,5.8,4.2c2.1,1.4,4.4,2.7,6.8,4 c2.4,1.3,5,2.6,7.7,3.8c2.7,1.2,5.6,2.4,8.6,3.6c4.8,1.9,9.5,3.5,14,4.8c4.5,1.4,8.8,2.5,12.5,3.4c3.8,0.9,7,1.6,9.5,2.1 c2.5,0.5,4.2,0.8,4.9,0.9h92.3l0.2,0.8h-92.8c-1-0.2-6.7-1.1-14.7-3c-8-1.9-18.3-4.7-28.6-8.8c-10.2-4-20.4-9.2-28.3-15.7 c-7.8-6.4-13.3-14.2-14.4-23.3c-1.1-9,2.4-16.3,8.3-22.2c5.8-5.8,13.9-10.3,22.3-13.8c8.3-3.4,16.8-5.8,23.4-7.3 c6.6-1.5,11.4-2.3,12.3-2.4H552.6z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M114.8,5.9c0.9,0.1,5.7,0.9,12.3,2.4c6.6,1.5,15.1,3.9,23.4,7.3c8.3,3.4,16.5,7.9,22.3,13.8 c5.9,5.9,9.4,13.2,8.3,22.2c-1.1,9.2-6.7,16.9-14.4,23.3c-7.9,6.5-18.1,11.7-28.3,15.7c-10.3,4.1-20.6,6.9-28.6,8.8 c-8,1.9-13.7,2.8-14.7,3H2l0.2-0.8h92.3c0.7-0.1,2.4-0.4,4.9-0.9c2.5-0.5,5.7-1.2,9.5-2.1c3.7-0.9,8-2.1,12.5-3.4 c4.5-1.4,9.3-3,14-4.8c3-1.2,5.9-2.4,8.6-3.6c2.7-1.2,5.3-2.5,7.7-3.8c2.4-1.3,4.7-2.6,6.8-4c2.1-1.4,4-2.8,5.8-4.2 c2.2-1.8,4.2-3.6,5.9-5.5c1.7-1.9,3.2-3.8,4.4-5.7c1.2-1.9,2.2-3.9,3-5.9c0.7-2,1.2-4,1.5-6.1c0.3-2,0.3-4,0-6 c-0.2-1.9-0.7-3.8-1.4-5.7c-0.7-1.8-1.7-3.6-2.9-5.3c-1.2-1.7-2.6-3.4-4.3-5c-1.3-1.3-2.8-2.5-4.4-3.8c-1.6-1.2-3.4-2.4-5.3-3.5 c-1.9-1.1-3.9-2.3-6.1-3.3c-2.2-1.1-4.5-2.1-6.9-3.1c-3.8-1.6-7.7-2.9-11.4-4.1c-3.7-1.1-7.2-2.1-10.3-2.8 c-3.1-0.8-5.8-1.3-7.8-1.7c-2.1-0.4-3.5-0.7-4.1-0.8H31.2l0.2-0.6H114.8z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 292.9,0 292.9,38.6 292.9,39.3 292.9,45.1 292.9,45.7 292.9,57.5 292.9,58.2 292.9,64.2 292.9,64.9 293,109.8 291,109.8 291.1,64.9 291.1,64.2 291.1,58.2 291.1,57.5 291.1,45.7 291.1,45.1 291.1,39.3 291.1,38.6 291.1,0 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 397.9,104.9 398.4,109.8 396.4,109.8 395.9,104.9 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path>
      <path d="M 188.1,104.9 187.6,109.8 185.6,109.8 186.1,104.9 z" fill="#b59678" style={{strokeWidth: '0px', strokeOpacity: '1'}}></path></g>
      <image preserveAspectRatio="none" x="240" y="25" width="100" height="60"></image>
      <g transform="matrix(1,0,0,1,2,-10)" className="hoopLeft">
      <path d="M23.8,13.1v21.4l1,0l4.6-14L29.4,0.4l-1,0L23.8,13.1z" fill="#f7f8f8" style={{strokeWidth: '0px'}}></path>
      <path d="M29.2,20.5l-4.1,12.2l0-19.3l4-11.4L29.2,20.5z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path>
      <path d="M 28.2,13 26.6,18.1 26.6,27 28.2,22.1   z" fill="#fbfcfc" style={{strokeWidth: '0px'}}></path>
      <path d="M 26.9,25.5 26.9,18.2 28,14.3 28,22   z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path>
      <path d="M 24.8,13.1 23.8,13.1 23.8,34.5 24.8,34.5   z" fill="#f3f3f3" style={{strokeWidth: '0px'}}></path>
      <path d="M 29.3,0.4 28.3,0.3 23.8,13.1 24.8,13.1   z" fill="#f3f3f3" style={{strokeWidth: '0px'}}></path>
      <path d="M 4.9,21.9 7,21.9 8.4,33.5 16.6,54 11.2,70.9 5.3,43.3   z" fill={props.awayColor} style={{strokeWidth: '0px'}}></path>
      <path d="M 1.4,24.2 4.9,21.9 5.3,43.3 11.2,70.9 0.5,71.1   z" fill={props.awayColor} style={{strokeWidth: '0px'}}></path>
      <path d="M 4.8,18 1.4,21.1 6.3,15.4 23.8,18.1 23.8,21.4 23.8,19.5   z" fill="#e6e6e5" style={{strokeWidth: '0px'}}></path>
      <path d="M 1.4,24.2 1.4,21.1 4.8,18 23.8,19.5 23.8,21.4 7,21.9 4.9,21.9   z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path></g>
      
      <ellipse cx="50" cy="0" rx="5" ry="5" id="bball-1" fill="#cf5300" stroke="#cf5300" style={{fillOpacity: 1, strokeWidth: 1}} className="shot away make"></ellipse>
      <ellipse cx="90" cy="50" rx="10" ry="5" id="shot-401236318321" fill={props.homeColor} stroke={props.homeColor} style={{fillOpacity: .25, strokeWidth: 1}} className="shot home miss"></ellipse>
      <ellipse cx="200" cy="90" rx="10" ry="5" id="shot-401236318321" fill={props.homeColor} stroke={props.homeColor} style={{fillOpacity: 1, strokeWidth: 1}} className="shot home miss"></ellipse>
      <ellipse cx="530" cy="0" rx="5" ry="5" id="bball-2" fill="#cf5300" stroke="#cf5300" style={{fillOpacity: 1, strokeWidth: 1}} className="shot away make"></ellipse>
      <ellipse cx="450" cy="25" rx="10" ry="5" id="shot-401236318352" fill={props.awayColor} stroke={props.awayColor} style={{fillOpacity: .25, strokeWidth: 1}} className="shot away make"></ellipse>
      <ellipse cx="375" cy="55" rx="10" ry="5" id="shot-401236318352" fill={props.awayColor} stroke={props.awayColor} style={{fillOpacity: 1, strokeWidth: 1}} className="shot away make"></ellipse>
      
      <g transform="matrix(1,0,0,1,552,-10)" className="hoopRight"><path d="M1.6,0.3l-1,0L0.5,20.5l4.6,14l1,0V13.1L1.6,0.3z" fill="#f7f8f8" style={{strokeWidth: '0px'}}></path>
      <path d="M0.8,2.1l4,11.4l0,19.3L0.8,20.5L0.8,2.1z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path>
      <path d="M 1.7,13 3.4,18.1 3.4,27 1.7,22.1   z" fill="#fbfcfc" style={{strokeWidth: '0px'}}></path>
      <path d="M 3.1,25.5 3.1,18.2 1.9,14.3 1.9,22   z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path>
      <path d="M 5.2,13.1 6.1,13.1 6.1,34.5 5.2,34.5   z" fill="#f3f3f3" style={{strokeWidth: '0px'}}></path>
      <path d="M 0.7,0.4 1.6,0.3 6.1,13.1 5.2,13.1   z" fill="#f3f3f3" style={{strokeWidth: '0px'}}></path>
      <path d="M 25,21.9 22.9,21.9 21.6,33.5 13.3,54 18.7,70.9 24.7,43.3   z" fill={props.homeColor} style={{strokeWidth: '0px'}}></path>
      <path d="M 28.6,24.2 25,21.9 24.7,43.3 18.7,70.9 29.4,71.1   z" fill={props.homeColor} style={{strokeWidth: '0px'}}></path>
      <path d="M 25.1,18 28.5,21.1 23.7,15.4 6.1,18.1 6.1,21.4 6.1,19.5   z" fill="#e6e6e5" style={{strokeWidth: '0px'}}></path>
      <path d="M 28.6,24.2 28.5,21.1 25.1,18 6.1,19.5 6.1,21.4 22.9,21.9 25,21.9   z" fill="#cdcccc" style={{strokeWidth: '0px'}}></path></g><g className="shots"></g><g className="shotAnimations"></g><g transform="matrix(1,0,0,1,30,12)" className="rimLeftTop">
      <path d="M0.5,1.3l0.5,0.3C2,0.6,4.7-0.1,7.5,0c2.6,0.1,4.4,1,4.5,1.9c0,0,0,0,0,0l0,0.5c0,0,0,0,0,0.1h-0.8   c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1C11,1.5,9.5,0.9,7.4,0.8C4.7,0.6,2,1.4,1.4,2.4c0,0,0,0.1-0.1,0.1H0l0.4-1.3L0.5,1.3z" fill="#e72b25" style={{strokeWidth: '0px'}}></path>
      <path d="M0.5,1.3l0.5,0.3C2,0.6,4.7-0.1,7.5,0c3,0.2,5,1.2,4.4,2.4c0,0-0.1,0.1-0.1,0.1h-0.6c0,0,0.1-0.1,0.1-0.1   c0.6-1-1.2-2-3.9-2.2C4.6,0.1,2,0.8,1.4,1.9c-0.1,0.2-0.1,0.5,0,0.7H0l0.4-1.3L0.5,1.3z" fill="#ef4623" style={{strokeWidth: '0px'}}></path>
      <path d="M6.3,4.2C6.3,4.2,6.3,4.2,6.3,4.2c0.1,0,0.1,0,0.1-0.1l1-3.3l1.3,3.3c0,0.1,0.1,0.1,0.1,0.1    c0.1,0,0.1,0,0.1-0.1l0.6-2.9l1,2.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1L11,1.5c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1    l-0.2,1.4l-1-2.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1L8.7,3.6L7.4,0.4c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0    c-0.1,0-0.1,0-0.1,0.1l-1,3.3L5,0.3c0,0-0.1-0.1-0.1-0.1h0c-0.1,0-0.1,0-0.1,0.1L3.7,3.4L3.1,0.9c0-0.1-0.1-0.1-0.1-0.1    c-0.1,0-0.1,0-0.1,0.1L2.1,2.9L1.9,1.6c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1l0.3,1.8C2,3.5,2,3.6,2.1,3.6    c0.1,0,0.1,0,0.1-0.1L3,1.4l0.5,2.5C3.5,3.9,3.6,4,3.6,4c0.1,0,0.1,0,0.1-0.1l1.1-3.2l1.3,3.4C6.2,4.2,6.2,4.2,6.3,4.2z" fill="#ffffff" style={{strokeWidth: '0px'}}></path></g>
      <g transform="matrix(1,0,0,1,30,14.8)" className="rimLeftBottom"><path d="M5.5,1.5C3.4,1.4,1.9,0.8,1.6,0c0,0,0,0,0,0l9.7,0C10.6,1,8.1,1.6,5.5,1.5z" fill="none" style={{strokeWidth: '0px'}}></path>
      <path d="M1.6,0c0.3,0.8,1.8,1.4,3.9,1.5C8.1,1.6,10.6,1,11.3,0l0.8,0c0,0.1,0,0.3-0.1,0.4c-0.6,1.2-3.6,2-6.6,1.8   C3.1,2.1,1.4,1.4,1,0.6L0.2,3.1l-0.1,0L0,0.7L0.2,0L1.6,0C1.6,0,1.6,0,1.6,0z" fill="#e72b25" style={{strokeWidth: '0px'}}></path>
      <path d="M5.5,1.5C3.4,1.4,1.9,0.8,1.6,0l9.7,0C10.6,1,8.1,1.6,5.5,1.5z" fill="none" style={{strokeWidth: '0px'}}></path><path d="M5.5,1.5C8.1,1.6,10.6,1,11.3,0l0.6,0c-0.8,1.1-3.6,1.9-6.5,1.7C3.1,1.6,1.5,0.9,1,0.1L0.1,0.7L0,0.7L0.2,0   l1.4,0C1.9,0.8,3.4,1.4,5.5,1.5z" fill="#ef4623" style={{strokeWidth: '0px'}}></path></g><g transform="matrix(1,0,0,1,31,16.5)" className="rimLeftNet"><path d="M9.3,4.2l1.3-4c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0.1l-1,3.2l0.1-2.5c0-0.1-0.1-0.1-0.1-0.1 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1L7.8,5.2L7.1,1.2C7.1,1.2,7,1.1,7,1.1c-0.1,0-0.1,0-0.1,0.1L5.4,5.2L4,1.1 C4,1.1,3.9,1,3.9,1c-0.1,0-0.1,0-0.1,0.1l-0.7,4L1.7,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.2,3.2L0.2,0.2c0-0.1-0.1-0.1-0.2-0.1C0,0.1,0,0.2,0,0.3l1.7,4.5l0.2,2.9 L1.7,9.5c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0L2.6,9L3,9.9c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0L4.4,9l1,1c0,0,0.1,0,0.1,0h0 c0,0,0.1,0,0.1,0l1-1l1.2,1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1l0.5-0.8L9,9.7c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1L9.1,7.7L9.3,4.2 z M8.9,7.3L8.5,6.7c0,0,0,0,0,0L9,5.1L8.9,7.3z M8.3,8.5L7.9,7.7L8.4,7l0.5,0.7L8.3,8.5z M6.5,8.6L5.6,7.8l0.9-0.9l1.1,0.9L6.5,8.6 z M4.4,8.6L3.3,7.7l1.1-0.8l0.8,0.9L4.4,8.6z M2.6,8.4L2.2,7.7l0.4-0.8L3,7.7L2.6,8.4z M2.7,6.6C2.7,6.6,2.7,6.6,2.7,6.6l0.4-0.7 c0,0,0,0,0,0l1.1,0.9L3.2,7.5L2.7,6.6z M4.6,6.6l0.8-0.9l1,1L5.4,7.6L4.6,6.6z M6.7,6.6l1.1-0.8l0.5,0.9c0,0,0,0,0,0L7.8,7.5 L6.7,6.6z M9.1,1.6L9,4.2L8.3,6.4L7.9,5.7L9.1,1.6z M6.9,1.7l0.7,3.9L6.5,6.5l-1-1L6.9,1.7z M3.9,1.7l1.3,3.8l-0.8,1L3.3,5.6 L3.9,1.7z M1.8,1.8L3,5.6L2.6,6.3L2,4.7L1.8,1.8z M2.4,6.6C2.4,6.6,2.5,6.6,2.4,6.6L2.2,7.2L2,5.5L2.4,6.6z M2.1,8.1l0.3,0.6L2,9.2 L2.1,8.1z M3.2,9.6L2.7,8.7c0,0,0,0,0,0l0.5-0.8l1,0.9L3.2,9.6z M5.5,9.7L4.6,8.8l0.9-0.9l0.9,0.9L5.5,9.7z M7.8,9.6L6.7,8.8l1-0.9 l0.4,1c0,0,0,0,0,0L7.8,9.6z M8.5,8.8l0.4-0.7l0.1,1.2L8.5,8.8z" fill="#ffffff" style={{strokeWidth: '0px'}}></path></g>
      <g transform="matrix(1,0,0,1,542,12)" className="rimRightTop"><path d="M11.4,1.3l-0.5,0.3C10,0.6,7.3-0.1,4.5,0C1.9,0.2,0.1,1,0,2c0,0,0,0,0,0l0,0.5c0,0,0,0,0,0.1h0.8 c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1C1,1.5,2.5,0.9,4.6,0.8c2.7-0.1,5.4,0.6,5.9,1.6c0,0,0,0.1,0.1,0.1h1.4l-0.4-1.3L11.4,1.3z" fill="#e72b25" style={{strokeWidth: '0px'}}></path><path d="M11.4,1.3l-0.5,0.3C10,0.6,7.3-0.1,4.5,0c-3,0.2-5,1.2-4.4,2.4c0,0,0.1,0.1,0.1,0.1h0.6 c0,0-0.1-0.1-0.1-0.1c-0.6-1,1.2-2,3.9-2.2c2.7-0.1,5.4,0.6,5.9,1.6c0.1,0.2,0.1,0.5,0,0.7h1.4l-0.4-1.3L11.4,1.3z" fill="#ef4623" style={{strokeWidth: '0px'}}></path>
      <path d="M5.7,4.2C5.7,4.2,5.7,4.2,5.7,4.2c-0.1,0-0.1,0-0.1-0.1l-1-3.3L3.3,4.1c0,0.1-0.1,0.1-0.1,0.1 c-0.1,0-0.1,0-0.1-0.1L2.4,1.1l-1,2.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1L0.9,1.5c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1 l0.2,1.4l1-2.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1l0.6,2.9l1.3-3.2c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0.1 l1,3.3l1.2-3.4c0,0,0.1-0.1,0.1-0.1h0c0.1,0,0.1,0,0.1,0.1l1.1,3.1l0.5-2.5c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1l0.8,2.1 L10,1.6c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1L10,3.5c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1L9,1.4L8.4,3.9 C8.4,3.9,8.4,4,8.3,4C8.3,4,8.2,4,8.2,3.9L7.1,0.7L5.8,4.2C5.8,4.2,5.7,4.2,5.7,4.2z" fill="#ffffff" style={{strokeWidth: '0px'}}></path></g><g transform="matrix(1,0,0,1,542,14.8)" className="rimRightBottom"><path d="M6.6,1.5c2.1-0.1,3.6-0.7,3.9-1.5c0,0,0,0,0,0H0.8C1.5,1,4,1.6,6.6,1.5z" fill="none" style={{strokeWidth: '0px'}}></path>
      <path d="M10.6,0c-0.3,0.8-1.8,1.4-3.9,1.5C4,1.6,1.5,1,0.8,0H0c0,0.1,0,0.3,0.1,0.4c0.6,1.2,3.6,2,6.6,1.8   c2.3-0.1,4-0.8,4.4-1.6l0.8,2.5l0.1,0l0.1-2.4L11.9,0L10.6,0C10.6,0,10.6,0,10.6,0z" fill="#e72b25" style={{strokeWidth: '0px'}}></path>
      <path d="M6.6,1.5c2.1-0.1,3.6-0.7,3.9-1.5H0.8C1.5,1,4,1.6,6.6,1.5z" fill="none" style={{strokeWidth: '0px'}}></path><path d="M6.6,1.5C4,1.6,1.5,1,0.8,0H0.2C1,1.1,3.8,1.9,6.7,1.7c2.3-0.1,3.9-0.8,4.4-1.6L12,0.7l0.1,0L11.9,0h-1.4   C10.2,0.8,8.7,1.4,6.6,1.5z" fill="#ef4623" style={{strokeWidth: '0px'}}></path></g><g transform="matrix(1,0,0,1,542,16.5)" className="rimRightNet">
      <path d="M9.3,4.2l1.3-4c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1,0-0.2,0.1l-1,3.2l0.1-2.5c0-0.1-0.1-0.1-0.1-0.1 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1L7.8,5.2L7.1,1.2C7.1,1.2,7,1.1,7,1.1c-0.1,0-0.1,0-0.1,0.1L5.4,5.2L4,1.1 C4,1.1,3.9,1,3.9,1c-0.1,0-0.1,0-0.1,0.1l-0.7,4L1.7,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.2,3.2L0.2,0.2c0-0.1-0.1-0.1-0.2-0.1C0,0.1,0,0.2,0,0.3l1.7,4.5l0.2,2.9 L1.7,9.5c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0L2.6,9L3,9.9c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0L4.4,9l1,1c0,0,0.1,0,0.1,0h0 c0,0,0.1,0,0.1,0l1-1l1.2,1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1l0.5-0.8L9,9.7c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1L9.1,7.7L9.3,4.2 z M8.9,7.3L8.5,6.7c0,0,0,0,0,0L9,5.1L8.9,7.3z M8.3,8.5L7.9,7.7L8.4,7l0.5,0.7L8.3,8.5z M6.5,8.6L5.6,7.8l0.9-0.9l1.1,0.9L6.5,8.6 z M4.4,8.6L3.3,7.7l1.1-0.8l0.8,0.9L4.4,8.6z M2.6,8.4L2.2,7.7l0.4-0.8L3,7.7L2.6,8.4z M2.7,6.6C2.7,6.6,2.7,6.6,2.7,6.6l0.4-0.7 c0,0,0,0,0,0l1.1,0.9L3.2,7.5L2.7,6.6z M4.6,6.6l0.8-0.9l1,1L5.4,7.6L4.6,6.6z M6.7,6.6l1.1-0.8l0.5,0.9c0,0,0,0,0,0L7.8,7.5 L6.7,6.6z M9.1,1.6L9,4.2L8.3,6.4L7.9,5.7L9.1,1.6z M6.9,1.7l0.7,3.9L6.5,6.5l-1-1L6.9,1.7z M3.9,1.7l1.3,3.8l-0.8,1L3.3,5.6 L3.9,1.7z M1.8,1.8L3,5.6L2.6,6.3L2,4.7L1.8,1.8z M2.4,6.6C2.4,6.6,2.5,6.6,2.4,6.6L2.2,7.2L2,5.5L2.4,6.6z M2.1,8.1l0.3,0.6L2,9.2 L2.1,8.1z M3.2,9.6L2.7,8.7c0,0,0,0,0,0l0.5-0.8l1,0.9L3.2,9.6z M5.5,9.7L4.6,8.8l0.9-0.9l0.9,0.9L5.5,9.7z M7.8,9.6L6.7,8.8l1-0.9 l0.4,1c0,0,0,0,0,0L7.8,9.6z M8.5,8.8l0.4-0.7l0.1,1.2L8.5,8.8z" fill="#ffffff" style={{strokeWidth: '0px'}}></path></g><g className="indicator-wrapper" style={{display: 'none'}}></g><g className="shotDetails"></g>
      <desc>Created with Snap</desc>
    </svg>
  );
};

export default BasketballCourt;